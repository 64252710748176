import React from 'react'
import {
    FontWeights, ILabelStyles, IStackStyles, IStackTokens, IStyleSet, ITextStyles,
    Label, Pivot, PivotItem, Stack, Text, Spinner, Link, Toggle
} from '@fluentui/react';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useApi, ApiParameters } from '../../hooks/useApi';
import devOpsApi, { GetSessionRequest } from '../../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../../statusMessageBar';
import { Session } from '../../models/session';
import { SessionOverView } from '../../sessions/overView';
import { EventView } from '../../sessions/eventView';
import { SubDocView } from '../../sessions/subDocView';
import { PcdDownloadButton } from '../../sessions/pcdDownloadButton';
import { PcdEntitiesView } from '../../sessions/pcdEntitiesView';
import { PublishRecordStateView } from '../../sessions/publishRecordStateView';
import { DocumentMappingView } from '../../sessions/documentMappingView';
import { TestMappingView } from '../../sessions/testMappingView';
import { RateCardView } from '../../sessions/rateCardView';
import { RatingFeedDownloadButton } from '../../sessions/ratingFeedDownloadButton';
import { Environment, getEnvironment } from '../../../config/environmentConfig';
import { RatingFeedBigVersion } from '../../sessions/ratingFeedBigVersion';
import { RatringFeedState} from '../../sessions/ratingFeedState';
import { RatingFeedPublishSession } from '../../models/ratingFeedPublishSession';
import { RatingFeedPublishSessionByBigcatIdView } from '../../sessions/ratingFeedPublishSessionByBigcatIdView';
import { RatingFeedPublishSessionView } from '../../sessions/ratingFeedPublishSessionView';
import { DataProcessorView } from '../../sessions/dataProcessorView';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const itemStyles: React.CSSProperties = {
    width: 150,
};
const stackLinkStyles: IStackStyles = {
    root: {
        width: 300,
    },
};
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 auto',
        color: '#605e5c',
    },
};
const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
};
const tabStyles: IStackStyles = {
    root: {
        width: 30,
    },
};
export function SessionView() {
    const params = useParams();
    const documentId = params.documentId;
    const revisionId = params.sessionId;
    const [session, updateSession] = useState<Session>();
    const [bigIdVersion, updateBigIdVersion] = useState<string[]>([]);
    const [bigcatVersion, updateBigcatVersion] = useState<string>("");
    const handleVersionChange:(version: string) => void = (version: string) => {
        updateBigcatVersion(version);
      };
    const handlePublishSessionsChange:(publishSessions: RatingFeedPublishSession[]) => void = (publishSessions: RatingFeedPublishSession[]) => {
        updatePublishSessions(publishSessions);
    }
    const [documentType, updateDocumentType] = useState<string[]>([]);
    const [publishMode, updatePublishMode] = useState<string[]>([]);
    const [publishSessions,updatePublishSessions] = useState<RatingFeedPublishSession[]>([]);
    const [hasbigcatVersion, updateHasbigcatVersion] = useState<boolean>(false);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<Session>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const overView = useMemo(() => session !== undefined && (<SessionOverView documentId={session.documentId} sessionId={session.sessionId} session={session} />), [session])
    const eventView = useMemo(() => session !== undefined && (<EventView documentId={session.documentId} sessionId={session.sessionId} bigIdVersion='' />), [session])
    const subDocView = useMemo(() => session !== undefined && (<SubDocView documentId={session.documentId} sessionId={session.sessionId} />), [session])
    const rateCardView = useMemo(() => documentId !== undefined && revisionId !== undefined && (<RateCardView documentId={documentId} revisionId={revisionId} />), [documentId, revisionId])
    const dataProcessorView = useMemo(() => session !== undefined && (<DataProcessorView documentId={session.documentId} sessionId={session.sessionId} session={session} />), [session])
    const pcdDownloadButtonView = useMemo(() => session !== undefined && (<PcdDownloadButton documentId={documentId || ""} revisionId={revisionId || ""} />), [session, documentId, revisionId])
    const pcdEntitiesView = useMemo(() => session !== undefined && (<PcdEntitiesView documentId={documentId || ""} revisionId={revisionId || ""} publishOptions={session?.publishOptions || ""} getDocumentTypes={updateDocumentType} />), [session, documentId, revisionId])
    const publishRecordStateView = useMemo(() => documentType.length !== 0 && (<PublishRecordStateView documentId={documentId || ""} revisionId={revisionId || ""} documentType={documentType || ""} getBigIdVersion={updateBigIdVersion} />), [documentId, revisionId, documentType])
    const documentMappingView = useMemo(() => bigIdVersion.length !== 0 && (<DocumentMappingView bigIdVersionIds={bigIdVersion} />), [bigIdVersion])
    const testMappingView = useMemo(() => session?.publishOptions.includes("GenerateTestPcd") && bigIdVersion.length !== 0 && (<TestMappingView bigIdVersionIds={bigIdVersion} configurationName={""} />), [session, bigIdVersion])
     const ratingFeedPublishSessionByBigcatIdView = useMemo(() => (bigcatVersion!=undefined||bigcatVersion!="") && (<RatingFeedPublishSessionByBigcatIdView productId={documentId || ""} bigcatVersion={bigcatVersion||""} revisionId={revisionId || ""} setSessionItem={handlePublishSessionsChange} getPublishModes={updatePublishMode}/>), [bigcatVersion])
     const ratingFeedPublishSessionView = useMemo(() => session !== undefined && (<RatingFeedPublishSessionView revisionId={revisionId || ""} getPublishModes={updatePublishMode} bigIdVersion=''/>), [session, revisionId])
     const ratingFeedDownloadButtonView = useMemo(() => publishMode.length !== 0 && (<RatingFeedDownloadButton productId={documentId || ""} revisionId={revisionId || ""} publishMode={publishMode || ""} />), [publishMode, documentId, revisionId])
    const ratingFeedBigcatVersionView = useMemo(() => session !==undefined && (<RatingFeedBigVersion setbigCatVersion={handleVersionChange}  documentId={documentId || ""} revisionId={revisionId || ""} />), [session,documentId, revisionId])
    const ratingFeedStateView = useMemo(() => (bigcatVersion !== undefined && documentId !== undefined && revisionId !== undefined && session?.correlationVector !== undefined && session.createdDateTime !== undefined) && (<RatringFeedState  bigcatVersion={bigcatVersion || ""} productId={documentId || ""} revisionId={revisionId || ""} cv={session.correlationVector || ""} createdDateTime={session.createdDateTime.toString() || ""} />), [bigcatVersion, documentId, revisionId, session?.correlationVector, session?.createdDateTime.toString()])
   
    useEffect(() => {
        const request = {
            documentId: documentId,
            sessionId: revisionId,
        } as GetSessionRequest;

        updateApiParameters([devOpsApi.getSession(request)]);
    }, [documentId, revisionId]);


    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            var sessions = response as Session[];
            updateSession(sessions[0] as Session);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const handleLinkClick = (item?: PivotItem) => {
        if (documentType && documentType.length !== 0 && item && item.props?.headerText !== "PCD") {
            updateDocumentType([]);
            updateBigIdVersion([]);
        }

        if (publishMode && publishMode.length !== 0 && item && item.props?.headerText !== "RatingFeed") {
            updatePublishMode([]);
        }
    };
    function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        updateHasbigcatVersion(checked || false);
        
      }
    const generateGenevaLink = (session: Session | undefined) => {
        if (session) {
            const namespaces = +getEnvironment() === Environment.PROD ? 'OfferStoreIngestionProd,BigCatPublishNsPROD,BigCatStagingPROD' : 'OfferStoreIngestionInt,BigCatPublishNsINT,BigCatStagingINT'

            var query = `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${session.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true`
                + `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log`
                + `&kqlClientQuery=source&chartEditorVisible=true&chartType=column`;

            if (session.correlationVector != null) {
                query = query + `&conditions=[["cV","contains","${encodeURI(session.correlationVector.substring(0, session.correlationVector.indexOf('.')))}"]]`;
            }

            return query;
        }

        return '';
    };

    return (
        <>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <Text variant="xxLarge" styles={boldStyle}>
                    Session
                </Text>
                <Text>DocumentId: {session?.documentId}</Text>
                <Text>RevisionId: {session?.sessionId}</Text>
                <Text>CV: {session?.correlationVector}</Text>
                <Text>Create Date: {session?.createdDateTime}</Text>
                <Text>Ingestion State: {session?.state}</Text>
                <Text>Publish Options: {session?.publishOptions}</Text>
                <Text>ExternalPartnerData: {session?.externalPartnerData}</Text>
                <Stack horizontal styles={stackLinkStyles} tokens={stackTokens}>
                    <span style={itemStyles}><Link href={generateGenevaLink(session)}
                        title="Go to Geneva"
                        aria-label="Go to Geneva"
                        target='_blank'>
                        Go to Geneva
                    </Link></span>
                    <span style={itemStyles}><Link href={"/explorer/intents/" + session?.sessionId + "/" + session?.documentId}
                        title="Go to Intents Explorer"
                        aria-label="Go to Intents Explorer"
                        target='_blank'>
                        Go to Intents Explorer
                    </Link></span>
                </Stack>
                <Text variant="xxLarge" styles={boldStyle}>
                    Details
                </Text>
            </Stack>

            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                {session !== undefined || (isLoading && <Spinner label="Loading..." />)}
                <StatusMessageBar message={statusMessage} isMultiline />
                <Pivot aria-label="Basic Pivot Example" onLinkClick={handleLinkClick}>
                    <PivotItem headerText="Overview">
                        <div style={{width:1200}}>{overView}</div>
                       
                    </PivotItem>
                    <PivotItem headerText="Events">
                    <div style={{width:1400}}> {eventView}</div>
                    </PivotItem>
                    <PivotItem headerText="Subdocuments">
                        {subDocView}
                    </PivotItem>
                    <PivotItem headerText="PCD" >
                        <div className="ms-Grid-col ms-xl10">
                            {pcdDownloadButtonView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>ProductContainerDocumentEntities</Label>
                            {pcdEntitiesView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>PublishRecord/PublishState</Label>
                            {publishRecordStateView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>DocumentMapping</Label>
                            {documentMappingView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>TestDocumentMapping</Label>
                            {testMappingView}
                        </div>
                    </PivotItem>
                    <PivotItem headerText="RatingFeed">
                    <Toggle label="Check BigCat Versions"  onText="On" offText="Off" onChange={_onChange} />
                    {hasbigcatVersion?(
                    <div>
                        <div  className="ms-Grid-col ms-xl2">
                            {ratingFeedBigcatVersionView}
                        </div>
                        <div>
                                {ratingFeedStateView}
                        </div>
                      
                        <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>PublishSession</Label>
                            {ratingFeedPublishSessionByBigcatIdView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            {ratingFeedDownloadButtonView}
                        </div>
                        </div>):(
                            <div>
                                 <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>PublishSession</Label>
                            {ratingFeedPublishSessionView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            {ratingFeedDownloadButtonView}
                        </div>
                            </div>
                        )}
                    </PivotItem>
                    <PivotItem headerText="RateCard">
                        {rateCardView}
                    </PivotItem>
                    <PivotItem headerText="DataProcessor">
                        <div style={{width:1200}}>{dataProcessorView}</div>
                    </PivotItem>
                </Pivot>
            </Stack></>
    );
}
