import { Environment, getEnvironment } from "./environmentConfig";

export enum EndpointType {
  DevOpsApi,
  PcdLookupApi,
  ExternalApi,
  ExternalApiSwagger
}

export function getEndpoint(endpoint: EndpointType) {
  const env = getEnvironment();
  switch (endpoint) {
    case EndpointType.DevOpsApi:
      return getDevOpsApiEndpoint(env);
    case EndpointType.PcdLookupApi:
      return getPcdLookupApiEndpoint(env);
    case EndpointType.ExternalApi:
      return getExternalApiEndpoint(env);
    case EndpointType.ExternalApiSwagger:
      return getExternalApiSwaggerEndpoint(env);
    default:
      throw Error(`Not supported endpoint: ${endpoint}`);
  }
}

function getDevOpsApiEndpoint(env: Environment) {
  switch (+env) {
    case Environment.Local:
      return "https://localhost:5001";
    case Environment.INT:
      return "https://offerstore-int.commerce.microsoft.com/devops/eventtracking";
    case Environment.AIRCAPI:
      return "https://offerstore-aircapi.commerce.microsoft.com/devops/eventtracking";
    case Environment.AIRCAPI_INT:
      return "https://offerstore-aircapi-int.commerce.microsoft.com/devops/eventtracking";
    default:
      return "https://offerstore.commerce.microsoft.com/devops/eventtracking";
  }
}

function getPcdLookupApiEndpoint(env: Environment) {
  switch (+env) {
    case Environment.Local:
      return "https://localhost:5001";
    case Environment.INT:
      return "https://pcdlookup-dcatbackend.bigcatalog-int.commerce.microsoft.com";
    case Environment.AIRCAPI:
      return "https://pcdlookup-dcatbackend.bigcatalog-ac.commerce.microsoft.com";
    case Environment.AIRCAPI_INT:
      return "https://pcdlookup-dcatbackend.bigcatalog-aircapi-int.commerce.microsoft.com";
    default:
      return "https://pcdlookup-dcatbackend.bigcatalog.commerce.microsoft.com";
  }
}

function getExternalApiEndpoint(env: Environment) {
  switch (+env) {
    case Environment.Local:
      return "https://localhost:5002";
    case Environment.INT:
      return "https://offerstore-int.commerce.microsoft.com/externalapi";
    case Environment.AIRCAPI:
      return "https://offerstore-aircapi.commerce.microsoft.com/externalapi";
    case Environment.AIRCAPI_INT:
      return "https://offerstore-aircapi-int.commerce.microsoft.com/externalapi";
    default:
      return "https://offerstore.commerce.microsoft.com/externalapi";
  }
}

function getExternalApiSwaggerEndpoint(env: Environment) {
  switch (+env) {
    case Environment.Local:
      return "https://localhost:5002/swagger/v1/swagger.json";
    case Environment.INT:
      return "https://offerstore-int.commerce.microsoft.com/externalapi/swagger/v1/swagger.json";
    case Environment.AIRCAPI:
      return "https://offerstore-aircapi.commerce.microsoft.com/externalapi/swagger/v1/swagger.json";
    case Environment.AIRCAPI_INT:
      return "https://offerstore-aircapi-int.commerce.microsoft.com/externalapi/swagger/v1/swagger.json";
    default:
      return "https://offerstore.commerce.microsoft.com/externalapi/swagger/v1/swagger.json";
  }
}
