import React, { CSSProperties, useEffect, useState } from 'react';
import { Link, Spinner } from '@fluentui/react';
import { RatingFeedPublishSession } from '../models/ratingFeedPublishSession';
import { ApiParameters, useApi } from '../hooks/useApi';
import devOpsApi, { GetEventsRequest } from '../services/devOpsApiClient';
import { OrchestrationEvent } from '../models/orchestrationEvent';
import { StatusMessage } from '../statusMessageBar';
import { Session } from '../models/session';
import { Environment, getEnvironment } from '../../config/environmentConfig';
import { BigIdSession } from '../models/bigIdSessions';

enum Status {
  Init = 'init',
  Waiting = 'waiting',
  Normal = 'normal',
  Failure = 'failure',
}
const STATUS_COLORS: { [key in Status]: string } = {
  [Status.Init]: 'gray',
  [Status.Waiting]: 'orange',
  [Status.Normal]: 'green',
  [Status.Failure]: 'red',
};
const circleStyle: CSSProperties = {
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: '15px',
  color: 'white',
  marginLeft: '10px',
  marginRight: '10px',
  padding: '0',
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center' 
}
const lineStyle: CSSProperties = {
  backgroundColor: 'gray',
  flex: 1,
  height: '2px',
  alignSelf: 'center'
}
const arrowStyle: CSSProperties = {
  content: '""',
  position: 'absolute',
  top: '64px',
  left: '238px',
  borderTop: '6px solid transparent',
  borderBottom: '6px solid transparent',
  borderLeft: '6px solid gray',
}
const linkStyle: CSSProperties = {
  color: 'white',
  textDecorationColor: 'rgb(0, 69, 120)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}

const linkSpanStyle: CSSProperties = {
  marginTop:'20px'
}


const lineTimeStyle: CSSProperties = {
    marginBottom:'20px'
}
export interface RatringFeedStateProps {
  bigcatVersion: string,
  productId : string,
  revisionId : string,
  cv:string,
  createdDateTime : string
}
export function RatringFeedState(props: RatringFeedStateProps) {
  const [bigcatVersionState, setBigcatVersionState] = useState<Status[]>([]);
  const [costTime, setCostTime] = React.useState<string[]>([]);
  const [intervalTime, setIntervalTime] = React.useState<string[]>([]);
  const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
  const [ready, response, isLoading, error, execute] = useApi<any>();
  const [events, updateEvents] = React.useState<OrchestrationEvent[]>([]);
  const sessionMonitoringEvent = "sessionmonitoringevent";
  const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
  const [hasBigcatVersion, sethasBigcatVersion] = useState<boolean>(false);
  const [hasBigcatVersionFinish, sethasBigcatVersioFinish] = useState<boolean>(false);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  useEffect(() => {
    setIsFinish(false);
    if (props.bigcatVersion !== undefined&& props.bigcatVersion!="") {
      const request = {
        productId:props.productId,
        revisionId: props.revisionId,
      } as GetEventsRequest;
      updateApiParameters([devOpsApi.getEvents(request)]);
    }
  }, [props.bigcatVersion]);

  useEffect(() => {
    if (ready) {
      execute(apiParameters);
    }
  }, [apiParameters, ready]);

  useEffect(() => {
    if (response) {
        const documents = response.TrackingEvents as OrchestrationEvent[];
        if (documents.length === 0) {
            return;
        }
        const sortDocuments = documents.slice().sort((pre, after) => new Date(pre.eventTime.toString()).getTime() - new Date(after.eventTime.toString()).getTime());
        updateEvents(sortDocuments);

        if (statusMessage) {
            setStatusMessage(null);
        }
    }
}, [response]);

useEffect(() => {
  if (events.length > 0) {
      updateEventsInfo();
      setIsFinish(true);
  }
}, [events,props.bigcatVersion]);

function updateEventsInfo()
{
  let flag =false;
  let finishFlag =false;
  let classArray: Status[] = [Status.Init, Status.Init, Status.Init];
  let timeArray: string[] = ["", "", ""];
  let intervalArray: string[] = ["", "", "" ];
  const sessionMonitoringEvents = events.filter(e => e.eventType.toLocaleLowerCase() === sessionMonitoringEvent);
  let ratingFeedSyncServiceCount = 0;
  const now = new Date();
  let icsApiStartTime: Date = now;
  let icsApiEndTime: Date = now;
  let icsApiRecentEndTime: Date = now;
  let ratingFeedConverterServiceStartTime: Date = now;
  let ratingFeedConverterServiceEndTime: Date = now;
  let ratingFeedConverterServiceRecentEndTime: Date = now;
  let ratingFeedSyncServiceStartTime: Date = now;
  let ratingFeedSyncServiceEndTime: Date = now;
  let ratingFeedSyncServiceRecentEndTime: Date = now;
  sessionMonitoringEvents.forEach(element => {
    let eventName = JSON.parse(JSON.stringify(element.data)).EventName.toString();
    let subState = JSON.parse(JSON.stringify(element.data)).SubState.toString();
    switch (eventName) {
        case "ICSNewRequestEvent":
          if(isElementJsonExist(element))
          {
            if(bigcatVersionExist(element))
           {
            flag = true;
            if (subState === "Failed")
                classArray[0] = Status.Failure;
            else if (subState === "Success" && classArray[0] !== Status.Failure && classArray[0] !== Status.Normal)
                classArray[0] = Status.Waiting;
            if (Date.parse(icsApiStartTime.toString()) === Date.parse(now.toString()))
                icsApiStartTime = element.eventTime;
           }
          }
            break;
        case "ICSRequestCompleteEvent":
          if(isElementJsonExist(element))
          {
            if(bigcatVersionExist(element))
           {
            finishFlag = true;
            if (subState === "Failed" && classArray[0] !== Status.Normal)
                classArray[0] = Status.Failure;
            if (subState === "Success")
                classArray[0] = Status.Normal;
            if (Date.parse(icsApiEndTime.toString()) === Date.parse(now.toString()))
                icsApiEndTime = element.eventTime;
            icsApiRecentEndTime = element.eventTime;
           }
          }
            break;
        case "RatingFeedConverterServiceNewRequestEvent":
          if(isElementJsonExist(element))
          {
            if(bigcatVersionExist(element))
           {
            if (subState === "Failed")
                classArray[1] = Status.Failure;
            else if (subState === "Success" && classArray[1] !== Status.Failure && classArray[1] !== Status.Normal)
                classArray[1] = Status.Waiting;
            if (Date.parse(ratingFeedConverterServiceStartTime.toString()) === Date.parse(now.toString()))
                ratingFeedConverterServiceStartTime = element.eventTime;
          }
        }
            break;
        case "RatingFeedConverterServiceRequestCompleteEvent":
          if(isElementJsonExist(element))
          {
            if(bigcatVersionExist(element))
           {
            if (subState === "Failed" && classArray[1] !== Status.Normal)
                classArray[1] = Status.Failure;
            if(subState === "Skipped" && classArray[1] !== Status.Normal && classArray[1] !== Status.Failure)
                classArray[1] = Status.Normal;
            if (subState === "Success")
                classArray[1] = Status.Normal;
            if (Date.parse(ratingFeedConverterServiceEndTime.toString()) === Date.parse(now.toString()))
                ratingFeedConverterServiceEndTime = element.eventTime;
            ratingFeedConverterServiceRecentEndTime = element.eventTime;
           }
          }
            break;
        case "RatingFeedSyncServiceNewRequestEvent":
          if(isElementJsonExist(element))
          {
            if(bigcatVersionExist(element))
           {
            if (subState === "Failed")
                classArray[2] = Status.Failure;
            else if (subState === "Success" && classArray[2] !== Status.Failure && classArray[2] !== Status.Normal)
                classArray[2] = Status.Waiting;
            if (Date.parse(ratingFeedSyncServiceStartTime.toString()) === Date.parse(now.toString()))
                ratingFeedSyncServiceStartTime = element.eventTime;
           }
          }
            break;
        case "RatingFeedSyncServiceRequestCompleteEvent":
          if(isElementJsonExist(element))
          {
           if(bigcatVersionExist(element))
           {
            if (subState === "Failed" && classArray[2] !== Status.Normal)
                classArray[2] = Status.Failure;
            if (subState === "Success" || subState === "Skipped")
            {
                ratingFeedSyncServiceCount++;
                if (ratingFeedSyncServiceCount === 2)
                    classArray[2] = Status.Normal;
            }
            if (Date.parse(ratingFeedSyncServiceEndTime.toString()) === Date.parse(now.toString()))
                ratingFeedSyncServiceEndTime = element.eventTime;
            ratingFeedSyncServiceRecentEndTime = element.eventTime;
          }
        }
            break;
        default:
            break;
    }
});
timeArray[0] = getDateDiff(icsApiStartTime, icsApiRecentEndTime, now);
timeArray[1] = getDateDiff(ratingFeedConverterServiceStartTime, ratingFeedConverterServiceRecentEndTime, now);
timeArray[2] = getDateDiff(ratingFeedSyncServiceStartTime, ratingFeedSyncServiceRecentEndTime, now);
intervalArray[0] = getDateDiff(icsApiEndTime, ratingFeedConverterServiceStartTime, now);
intervalArray[1] = getDateDiff(ratingFeedConverterServiceEndTime, ratingFeedSyncServiceStartTime, now);
  setBigcatVersionState(classArray);
  setCostTime(timeArray);
  setIntervalTime(intervalArray);
  sethasBigcatVersion(flag);
  sethasBigcatVersioFinish(finishFlag);
}

function isElementJsonExist(element: OrchestrationEvent)
{
  return JSON.parse(JSON.stringify(element.data)).AdditionalInformation!=null &&JSON.parse(JSON.stringify(element.data)).AdditionalInformation!=undefined&& JSON.parse(JSON.stringify(element.data)).AdditionalInformation !=''
}

function bigcatVersionExist(element: OrchestrationEvent)
{
 return JSON.parse(JSON.parse(JSON.stringify(element.data)).AdditionalInformation).BigcatVersion == props.bigcatVersion

}
function getDateDiff(startDate: Date, endDate: Date, nowDate: Date)
{
    var sd = Date.parse(startDate.toString());
    var ed = Date.parse(endDate.toString());
    var now = Date.parse(nowDate.toString());
    if (sd === now || ed === now)
    {
        return "n/a";
    }
    var drr = ed - sd;
    var day = parseInt((drr / (24 * 60 * 60 * 1000)).toString());
    var hours = parseInt((drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000)).toString());
    var minutes = parseInt((drr % (60 * 60 * 1000) / (60 * 1000)).toString());
    var seconds = parseInt((drr % (60 * 1000) / 1000).toString());
    var result = (day === 0 ? "" : day + "d") + (hours === 0 ? "" : hours + "h") + (minutes === 0 ? "" : minutes + "m") + (seconds === 0 ? "" : seconds + "s");
    return result === ""||Number(result.split('s')[0])<0  ? "0s" : result;
}
const generateGenevaLink = (serviceName: string) => {
 
      const namespaces = +getEnvironment() === Environment.PROD ? 'OfferStoreIngestionProd,BigCatPublishNsPROD,BigCatStagingPROD' : 'OfferStoreIngestionInt,BigCatPublishNsINT,BigCatStagingINT'

      return `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${props.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true`
          + `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log`
          + `&conditions=[["cV","contains","${encodeURI(props.cv.substring(0, props.cv.indexOf('.')))}"],["ext_cloud_role","%3D%3D","${serviceName}"]]`;
 
};
  return (
    isFinish ? (
      <div>
   
        { hasBigcatVersion&&hasBigcatVersionFinish?(        
        <div style={{ display: 'flex', marginTop: '10px', width: '600px', justifyContent: 'space-between' }}>
          <div style={{ backgroundColor: STATUS_COLORS[bigcatVersionState[0]], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreingestionapi")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>ICS API</span><br/><span style={{...lineTimeStyle}}>{costTime[0]}</span></Link></div>
          <div style={{ ...lineStyle }}>
            <div style={{...lineTimeStyle}}>{intervalTime[0]}</div>
            <div style={arrowStyle} />
          </div>
          <div style={{ backgroundColor: STATUS_COLORS[bigcatVersionState[1]], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreratingfeedconverterservice")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>Converter</span><br/><span style={{...lineTimeStyle}}>{costTime[1]}</span></Link></div>
          <div style={{ ...lineStyle }}>
          <div style={{...lineTimeStyle}}>{intervalTime[1]}</div> 
          <div style={{ ...arrowStyle, left: '468px' }} /></div>
          <div style={{ backgroundColor: STATUS_COLORS[bigcatVersionState[2]], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreratingfeedsyncservice")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>Sync</span><br/><span style={{...lineTimeStyle}}>{costTime[2]}</span></Link></div> 
        </div>):props.bigcatVersion!=undefined&&props.bigcatVersion!=""?(        
          <div style={{ display: 'flex', marginTop: '10px', width: '600px', justifyContent: 'space-between' }}>
          <div style={{ backgroundColor: STATUS_COLORS[Status.Failure], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreingestionapi")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>ICS API</span><br/><span style={{...lineTimeStyle}}>n/a</span></Link></div>
          <div style={{ ...lineStyle }}>
            <div style={{...lineTimeStyle}}>n/a</div>
            <div style={arrowStyle} />
          </div>
          <div style={{ backgroundColor: STATUS_COLORS[bigcatVersionState[1]], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreratingfeedconverterservice")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>Converter</span><br/><span style={{...lineTimeStyle}}>{costTime[1]}</span></Link></div>
          <div style={{ ...lineStyle }}>
          <div style={{...lineTimeStyle}}>{intervalTime[1]}</div> 
          <div style={{ ...arrowStyle, left: '468px' }} /></div>
          <div style={{ backgroundColor: STATUS_COLORS[bigcatVersionState[2]], ...circleStyle }}><Link style={{...linkStyle}} href={generateGenevaLink("offerstoreratingfeedsyncservice")} title="Go to Geneva" target='_blank'><span style={{...linkSpanStyle}}>Sync</span><br/><span style={{...lineTimeStyle}}>{costTime[2]}</span></Link></div> 
        </div>):(<div></div>) }
      </div>)
  :(<Spinner label="Loading..." />));
 }
