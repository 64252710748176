import { useParams } from "react-router-dom";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { BigIdSession } from "../../models/bigIdSessions";
import { ApiParameters, useApi } from "../../hooks/useApi";
import { StatusMessage } from "../../statusMessageBar";
import devOpsApi, { GetBigIdSessionsRequest, GetSessionRequest } from "../../services/devOpsApiClient";
import {
    FontWeights, ILabelStyles, IStackStyles, IStackTokens, IStyleSet, ITextStyles,
    Label, Pivot, PivotItem, Stack, Text, Spinner, Link, Toggle
} from '@fluentui/react';
import { RatringFeedState } from "../../sessions/ratingFeedState";
import { Session } from "../../models/session";
import { RatingFeedPublishSessionView } from "../../sessions/ratingFeedPublishSessionView";
import { RatingFeedDownloadButton } from "../../sessions/ratingFeedDownloadButton";
import { EventView } from "../../sessions/eventView";
import { SubDocView } from "../../sessions/subDocView";
const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const itemStyles: React.CSSProperties = {
    width: 150,
};
const labelStyle:CSSProperties ={
    marginTop:'20px',
    marginLeft:'10px'
}
const stackLinkStyles: IStackStyles = {
    root: {
        width: 300,
    },
};
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 auto',
        color: '#605e5c',
    },
};
const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
};
const tabStyles: IStackStyles = {
    root: {
        width: 30,
    },
};
export function BigIdSessionsView() {
    const params = useParams();
    const [publishMode, updatePublishMode] = useState<string[]>([]);
    const [bigIdSessions, updateBigIdSessions] = React.useState<BigIdSession[]>([]);  
    const [requestStage, setRequestStage] = useState('');
    const [item, updateItem] = React.useState<BigIdSession>();
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<BigIdSession[]|Session[]>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const ratingFeedStateView = useMemo(() => (item?.bigcatVersion !==undefined && item.productId!==undefined && item.revisionId!==undefined && item.cv !== undefined && item.createdDateTime !== undefined)&& (<RatringFeedState bigcatVersion={item.bigcatVersion||""} productId={item.productId||""} revisionId= {item.revisionId||""} cv ={item.cv||""} createdDateTime={item.createdDateTime||""} />), [item?.bigcatVersion,item?.productId,item?.revisionId,item?.cv,item?.createdDateTime])
    const ratingFeedPublishSessionView = useMemo(() => item !== undefined && (<RatingFeedPublishSessionView revisionId={item?.revisionId || ""} getPublishModes={updatePublishMode} bigIdVersion={params.bigcatVersion as string}/>), [item, item?.revisionId])
    const ratingFeedDownloadButtonView = useMemo(() => publishMode.length !== 0 && (<RatingFeedDownloadButton productId={item?.productId || ""} revisionId={item?.revisionId || ""} publishMode={publishMode || ""} />), [publishMode, item?.productId, item?.revisionId])
    const eventView = useMemo(() => item !== undefined && (<EventView documentId={item?.productId} sessionId={item?.revisionId} bigIdVersion={params.bigcatVersion as string} />), [item])
    const subDocView = useMemo(() => item !== undefined && (<SubDocView documentId={item.productId} sessionId={item.revisionId} />), [item])
    useEffect(() => {

        const request = {
            bigId: params.bigId,
            bigcatVersion: params.bigcatVersion,
            cv:params.cv
        } as GetBigIdSessionsRequest;
        setRequestStage("requestBigSession")
        updateApiParameters([devOpsApi.getBigIdSessions(request)]);

    }, [params.bigId]);
    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            if (requestStage === 'requestBigSession')
            {
            var bigSessions = response as BigIdSession[];
            if (bigSessions.length === 0) {
                return;
            }
            updateBigIdSessions(bigSessions)
            updateItem(bigSessions[0] as BigIdSession)
            }
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    
    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const handleLinkClick = (item?: PivotItem) => {
        console.log(item)
    };

    return (
        <>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <Text variant="xxLarge" styles={boldStyle}>
                    BigId Session
                </Text>
                <Text>DocumentId: {item?.productId}</Text>
                <Text>RevisionId: {item?.revisionId}</Text>
                <Text>BigId: {item?.bigId}</Text>
                <Text>Create Date: {item?.createdDateTime}</Text>
                <Text>BigcatVersion : {item?.bigcatVersion}</Text>
                <Text>CV: {item?.cv}</Text>
                <Text>HasPrivateOffer: {item?.hasPrivateOffer ? "true" : "false"}</Text>
                <Text>IsFromOfferStore: {item?.isFromOfferStore ? "true" : "false"}</Text>
                <Text variant="xxLarge" styles={boldStyle}>
                    Details
                </Text>
            </Stack>

            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <Pivot aria-label="Basic Pivot Example" onLinkClick={handleLinkClick}>
                    <PivotItem headerText="Overview">
                    <Label style={{...labelStyle}}>BigcatVersion:{item?.bigcatVersion}</Label>
                    {ratingFeedStateView}
                    </PivotItem>
                    <PivotItem headerText="Events">
                    <div style={{width:1400}}> {eventView}</div>
                    </PivotItem>
                    <PivotItem headerText="Subdocuments">
                    {subDocView}
                    </PivotItem>
                    <PivotItem headerText="RatingFeed">
                    <div>
                                 <div className="ms-Grid-col ms-xl10">
                            <Label styles={labelStyles}>PublishSession</Label>
                            {ratingFeedPublishSessionView}
                        </div>
                        <div className="ms-Grid-col ms-xl10">
                            {ratingFeedDownloadButtonView}
                        </div>
                            </div>
                    </PivotItem>
                </Pivot>
            </Stack>
        </>
    );

}