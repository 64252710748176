import { Spinner, Stack, StackItem, IStyleSet, TooltipHost } from '@fluentui/react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, IDetailsListStyles } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { getHomeListColumns } from '../../models/homeListColumn';
import { Session } from '../../models/session';
import { getSessionListColumns } from '../../models/sessionListColumn';
import devOpsApi, { GetManifestSessionRequest, GetManifestSessionsRequest, GetSessionsRequest } from '../../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../../statusMessageBar';
import pcdApi, { GetPublishRecordHistoryRequest } from '../../services/pcdApiClient';
import { Pagination } from '../../pagination';
import { getIntentsListColumns } from '../../models/intentsListColumns';
import _ from 'lodash';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const disableStyle: Partial<IDetailsListStyles> = {
    root: {
        opacity: 0.4
    },
};

const enableStyle: Partial<IDetailsListStyles> = {
    root: {
        opacity: 1
    },
};

export interface SessionListProps {
    sessionId: string;
    searchList: SearchListProps;
}

export enum requestEnum {
    getPublishRecordHistory,
    getSessions
}
export interface SearchListProps {
    enabled: number,
    sessionId: string;
    startDate: string,
    endDate: string,
    cV: string,
    documentId: string
}

export function ManifestSessionListView(props: SessionListProps) {
    const [sessions, updateSessions] = React.useState<Session[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [request, updateRequest] = useState<GetManifestSessionsRequest>({} as GetManifestSessionsRequest);
    const [ready, response, isLoading, error, execute] = useApi<Session[]>();
    const [items, updateItems] = React.useState<Session[]>(sessions);
    const [position, setPosition] = React.useState("top");
    const columns: IColumn[] = [
        {
            key: 'sessionIdColumn',
            name: 'SessionId',
            fieldName: 'sessionId',
            minWidth: 120,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'createdDateTimeColumn',
            name: 'Date Created',
            fieldName: 'createdDateTime',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: Session) => {
                return <span>{item.createdDateTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'stateColumn',
            name: 'Ingestion State',
            fieldName: 'state',
            minWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'correlationVectorColumn',
            name: 'Correlation Vector',
            fieldName: 'correlationVector',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: Session) => {
                return (
                    <TooltipHost content={item.correlationVector}>
                        <span>{item.correlationVector?.substring(0, item.correlationVector.indexOf('.'))}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: 'publishOptionsColumn',
            name: 'Publish Options',
            fieldName: 'publishOptions',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        }
    ];
    const navigate = useNavigate();
    const selection = new Selection({
        onSelectionChanged: () => {
            updateSelectionDetails(_getSelectionDetails());
        },
    });
    const [selectionDetails, updateSelectionDetails] = React.useState<string>(_getSelectionDetails());

    //Pagination
    const [calculatedPages, setCalculatedPages] = useState<number>(1);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [initialPage, setInitialPage] = useState<boolean>(false);
    const pageSize = 50;

    const goToNextPage = () => {
        setCurrentPageIndex(currentPageIndex + 1);
        setInitialPage(false);
    };

    const goToPreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const initialPageIndex = () => {
        setCurrentPageIndex(0);
        setCalculatedPages(1);
        setInitialPage(true);
        setPosition("top");
    };

    React.useEffect(() => {
        if (initialPage) {
            return;
        }
        const request = {
            sessionId: props.searchList.sessionId,
            startTime: props.searchList.startDate,
            endTime: props.searchList.endDate,
            skip: pageSize * currentPageIndex,
            cV: props.searchList.cV,
            documentId: props.searchList.documentId,
        } as GetManifestSessionsRequest;

        updateRequest(request);

        if (items.length !== 0) {
            setPosition("bottom");
        }

    }, [currentPageIndex]);

    React.useMemo(() => {
        console.log("init")
        if (props.searchList.enabled !== 0) {

            initialPageIndex();

            const request = {
                sessionId: props.searchList.sessionId,
                startTime: props.searchList.startDate,
                endTime: props.searchList.endDate,
                cV: props.searchList.cV,
                documentId: props.searchList.documentId
            } as GetManifestSessionsRequest;
            updateRequest(request);
        }

    }, [props.searchList]);

    React.useEffect(() => {
        if (ready) {
  
                execute([devOpsApi.getManifestSessions(request)]);
            }
        }
    , [request, ready]);

    React.useEffect(() => {
        if (response) {      
            updateSessions(response as Session[]);
        }
    }, [response]);

    React.useEffect(() => {
        if (error) {
                setStatusMessage(error);
        }
    }, [error]);

    React.useEffect(() => {
        updateItems(sessions);
        setCalculatedPages(sessions.length);
    }, [sessions]);

    return (
            <Stack >
                <StackItem>
                    {position === "top" && isLoading && (<Spinner label="Loading..." />)}
                    <div style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
                        <div className={classNames.selectionDetails}>{items.length !== 0 && selectionDetails}</div>
                        <Announced message={selectionDetails} />
                        {items.length !== 0 && <DetailsList styles={isLoading ? disableStyle : enableStyle}
                            items={items}
                            compact={false}
                            columns={columns}
                            selectionMode={isLoading ? SelectionMode.none : SelectionMode.single}
                            getKey={_getKey}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={selection}
                            selectionPreservedOnEmptyClick={true}
                            onItemInvoked={_onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />}
                        <StatusMessageBar message={statusMessage} isMultiline />
                    </div></StackItem>
                <StackItem>
                    <Stack
                        horizontal
                    >
                        {items.length !== 0 && (<Pagination
                            total={0}
                            calculatedPages={calculatedPages}
                            pageSize={pageSize}
                            goToPreviousPage={goToPreviousPage}
                            goToNextPage={goToNextPage}
                            currentPageIndex={currentPageIndex}
                        />)}{position === "bottom" && isLoading && (<Spinner label="Loading..." />)}
                    </Stack>

                </StackItem>
            </Stack>
    );

    function _getKey(item: any, index?: number): string {
        return item.key;
    }

 
    function _onItemInvoked(item: any): void {
        console.log(item)
        navigate(`/explorer/manifestIngestion/${item.sessionId}`);
        
    
    }

    function _getSelectionDetails(): string {
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (selection.getSelection()[0] as Session).sessionId;
            default:
                return `${selectionCount} items selected`;
        }
    }

    function compare(elementA: any, elementB: any) {
        return new Date(elementB.generatedAt.toString()).getTime() - new Date(elementA.generatedAt.toString()).getTime()
    }
    function deduplicate(array: any[]): any[] {
        return _.uniqWith(array, (elementA: any, elementB: any) => elementA.revisionId === elementB.revisionId && elementA.documentId === elementB.documentId)
    }
}
